import FormGenerator from "components/FormGenerator/index";
import MessageDialog, {
  INFORMATION_DIALOG,
} from "components/MessageDialog/index";
import React, { useEffect, useState } from "react";
import APIs from "services/API";
import useAPI from "services/hook";
import { formItems } from "./formItems";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { CardContent, CardHeader } from "@mui/material";

const AddAgent = ({ location }) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    type: INFORMATION_DIALOG,
  });
  const apiEditAdmin = useAPI(APIs.apiEditAdmin);
  const apiCreateAgent = useAPI(APIs.apiCreateAgent);

  useEffect(() => {
    if (location?.state?.id) getAdminDetail(location?.state?.id);
  }, []);

  const getAdminDetail = async (id) => {
    setLoading(true);
    const result = await apiEditAdmin.request({ id });
    if (result) setInitialValues(result);
    setLoading(false);
  };

  const fetchData = async (values) => {
    setLoading(true);

    // let result = null;
    // if (location?.state?.id) {
    //   formData.id = location?.state?.id;
    //   result = await apiUpdateAdmin.request(formData);
    // } else {
    //   result = await apiCreateAdmin.request(formData);
    // }

    let result = await apiCreateAgent.request(values);

    if (result) setDialogProps({ isOpen: true });
    setLoading(false);
  };
  return (
    <Card>
      <CardHeader title="Add Agent" />
      <CardContent>
        <FormGenerator
          formItems={formItems}
          initialValues={initialValues}
          customClassName="justify-content-center"
          onSubmit={fetchData}
          onCancel={location?.state?.id ? () => navigate(-1) : null}
          loading={loading}
        />
      </CardContent>
      {dialogProps.isOpen && (
        <MessageDialog
          title={"Done successfully"}
          onConfirm={() => {
            setDialogProps({ isOpen: false });
            navigate("/agent/list");
          }}
        />
      )}
    </Card>
  );
};

export default AddAgent;
