import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import {
  createUserFeesRequestAction,
  getCurrenciesRequestAction,
  getUserDefaultFeeAction,
} from "redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "components/DropDown";
import { getUserDefaultsRequestAction } from "../../../../../redux/actions/usersActions";
import FeesCard from "./FeesCard";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import AppNumberInput from "components/AppNumberInput";
import { getEventsAction } from "redux/actions/GeneralActions";
import { showMessage } from "components/Modal";
import { LoadingButton } from "@mui/lab";
import { removeBalanceSeparator } from "constants/usefulFunctions";
// --------------------------------------------------------------------------------
const UserFees = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentsList, userFees } = useSelector((state) => ({
    userFees: state.customer.userFees,
    agentsList: state.agent.agentsList,
  }));

  const [currencies, setCurrencies] = useState([]);

  const [currencyId, setCurrencyId] = useState(0);
  const [agentId, setAgentId] = useState(0);
  const [eventId, setEventId] = useState(0);
  const [constant, setConstant] = useState("0.0");
  const [percent, setPercent] = useState("0.0");
  const [min, setMin] = useState("0.0");
  const [max, setMax] = useState("0.0");

  const [values, setValues] = useState(null);
  const [events, setEvents] = useState(null);
  const [eventsChanges, setEventChanges] = useState(false);

  const [moreDetails, setMoreDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const [postData, setPostData] = useState(null);

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
    dispatch(getCurrenciesRequestAction({ setCurrencies }));
    dispatch(getEventsAction(setEvents));
  }, []);

  const createPostData = () => {
    setPostData({
      agentId,
      currencyId,
      eventId,
      constant: removeBalanceSeparator(constant),
      percent: removeBalanceSeparator(percent),
      min: removeBalanceSeparator(min),
      max: removeBalanceSeparator(max),
    });
  };

  useEffect(() => {
    if (postData) {
      dispatch(
        createUserFeesRequestAction({
          postData,
          onSuccess: () => {
            dispatch(getUserDefaultsRequestAction());
            setCurrencyId([]);
            setAgentId("");
            setConstant("0.0");
            setPercent("0.0");
            setMin("0.0");
            setMax("0.0");
            showMessage({
              status: "success",
            });
          },
          setLoading,
        })
      );
    }
  }, [postData]);

  useEffect(() => {
    if (currencyId !== 2 && currencyId !== 0) {
      let newEvent = [];
      events.forEach((event) => {
        if (
          !(
            event.name.includes("createVirtualCard") ||
            event.name.includes("activateCard")
          )
        ) {
          newEvent.push(event);
        }
      });
      setEvents(newEvent);
    }
    if (currencyId === 2) {
      dispatch(getEventsAction(setEvents));
    }
  }, [currencyId]);

  useEffect(() => {
    if (currencyId && agentId && eventId) {
      setMoreDetails(true);
      dispatch(
        getUserDefaultFeeAction({ currencyId, agentId, eventId }, setValues)
      );
    }
  }, [currencyId, agentId, eventId]);
  useEffect(() => {
    if (events) {
      events.forEach((event) => {
        if (event.type && !event.name.includes("-")) {
          event.name += ` - ${event.type} `;
        }
      });
    }
  }, [events]);

  useEffect(() => {
    if (values) {
      setPercent(values.percent);
      setConstant(values.constant);
      setMin(values.min);
      setMax(values.max);
    }
  }, [values]);

  return (
    <Card>
      <CardHeader title="Default Fee" />
      <CardContent>
        <Grid container spacing={3}>
          {userFees?.map((wallet) => (
            <Grid item xs={12} md={6} lg={4}>
              <FeesCard wallet={wallet} currencies={currencies} />
            </Grid>
          ))}
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DropDown
                      label="Agent"
                      schema={{ label: "name", value: "id" }}
                      items={agentsList.records}
                      value={agentId}
                      onChange={(event) => setAgentId(event.target.value)}
                    />
                  </Grid>
                  {agentId ? (
                    <Grid item xs={12}>
                      <DropDown
                        label="Currency"
                        schema={{ label: "code", value: "id" }}
                        items={currencies}
                        value={currencyId}
                        onChange={(event) => setCurrencyId(event.target.value)}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {currencyId ? (
                    <Grid item xs={12}>
                      <DropDown
                        label="Event"
                        schema={{ label: "name", value: "id" }}
                        items={events}
                        value={eventId}
                        onChange={(event) => setEventId(event.target.value)}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {moreDetails && (
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <AppNumberInput
                          thousandSeparator={true}
                          decimalScale={2}
                          label="Constant"
                          value={constant}
                          onChange={(event) => setConstant(event.target.value)}
                          inputMode="numeric"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AppNumberInput
                          thousandSeparator={true}
                          decimalScale={2}
                          label="Percent"
                          value={percent}
                          onChange={(event) => setPercent(event.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AppNumberInput
                          thousandSeparator={true}
                          decimalScale={2}
                          label="Min"
                          value={min}
                          onChange={(event) => setMin(event.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AppNumberInput
                          thousandSeparator={true}
                          decimalScale={2}
                          label="Max"
                          value={max}
                          onChange={(event) => setMax(event.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <CardActions>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  loading={loading}
                  onClick={() => createPostData()}
                >
                  Submit
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserFees;
